import React from 'react';
import groupBy from 'lodash/groupBy';
import Checkbox from '@kyruus/checkbox';
import { Select } from '@kyruus/select';
import { fromTheme } from '@kyruus/ui-theme';
import styled from '@emotion/styled';

const Container = styled.div`
  padding: ${fromTheme('spacing_small')};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${fromTheme('spacing_small')} ${fromTheme('spacing_large')};

  // Checkbox is a label element with built-in bottom margin
  label {
    margin-bottom: 0;
  }
`;

const PreFilters = ({
  /** Configuration that comes from customer service. Includes search widget options */
  config,
  /** current state of the filter input values
   * @type {{ acceptingNewPatients: boolean, selectedInsurance: string }} */
  filters,
  /** @type {(changes: Partial<Filters>) => void} */
  onChange
}) => {
  const preFiltersConfig = config.search_widget.pre_search_filters;
  const preFiltersConfigIsValid =
    Array.isArray(preFiltersConfig) && !!preFiltersConfig.length;
  if (!preFiltersConfigIsValid) {
    return null;
  }

  const {
    accepting_new_patients: [acceptingNewPatientsConfig] = [],
    insurance_plans: [insurancePlansConfig] = []
  } = groupBy(preFiltersConfig, 'facet');
  return (
    <Container>
      {!!acceptingNewPatientsConfig?.filters.length && (
        <Checkbox
          hasError={false}
          label={acceptingNewPatientsConfig.filters[0].label}
          checked={filters.acceptingNewPatients}
          onChange={(value) => onChange({ acceptingNewPatients: value })}
        />
      )}
      {!!insurancePlansConfig?.filters?.length && (
        <Select
          useTypeahead={true}
          placeholder={'Choose Insurance'}
          items={insurancePlansConfig.filters}
          selectedItem={filters.selectedInsurance}
          maxHeight={300}
          onChange={(value) => onChange({ selectedInsurance: value })}
          itemListWidth="auto"
          itemListMaxWidth="500px"
        />
      )}
    </Container>
  );
};

export default PreFilters;
