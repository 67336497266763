import React from 'react';
import SearchWidgetV2, { DefaultSearchWidget } from '@kyruus/search-widget-v2';

const SearchWidget = ({
  config,
  searchWidgetConfig,
  customerCode,
  options,
  typeaheadTerms,
  location,
  onSelect,
  onSubmit,
  onInputFocus
}) => {
  // whether or not we should use the old search_widget config object in CS
  const useLegacyConfig = config.darkship_KENG_31725 ? false : true;

  // the starting point for the config we pass to the widget; additional PMC-only overrides are passed below
  const widgetConfig = useLegacyConfig
    ? convertFromCustomerServiceConfig(config, customerCode)
    : searchWidgetConfig;

  // TODO see if we can remove this default widget at some point (the widget will accept a partial config
  // or NO config and still function with the defaults it maintains internally, though the theme would still
  // need to be passed here)
  if (!useLegacyConfig && configIsInvalid(searchWidgetConfig)) {
    // still use the theme object from CS in this case
    return (
      <DefaultSearchWidget
        id={customerCode}
        config={{
          customer_code: customerCode,
          index: config.index,
          params: {
            focus_on_widget: true
          },
          theme: config.theme
        }}
        options={options}
        useV8={false}
        useLegacyConfig={false}
        onSelect={onSelect}
        onSubmit={onSubmit}
        onInputFocus={onInputFocus}
        typeaheadTerms={typeaheadTerms}
        location={location}
      />
    );
  }

  // NOTE: index and focus_on_widget are always going to be passed as PMC-only overrides here
  return (
    <SearchWidgetV2
      id={customerCode}
      config={{
        ...widgetConfig,
        index: config.index,
        params: {
          ...widgetConfig.params,
          focus_on_widget: true
        }
      }}
      options={options}
      useV8={config.darkship_use_list_page_searchv9 ? false : true}
      useLegacyConfig={useLegacyConfig}
      onSelect={onSelect}
      onSubmit={onSubmit}
      onInputFocus={onInputFocus}
      typeaheadTerms={typeaheadTerms}
      location={location}
    />
  );
};

/**
 * Util function that tranposes the legacy configs in CS to a shape that the widget
 * expects, which is the same shape returned by the search-widget-api
 *
 * @param {object} config - the PMC config from CS
 * @returns object - a SW config object that the widget expects
 */
export function convertFromCustomerServiceConfig(config, customerCode) {
  const displayDictionary = {};

  for (const key in config.application_string_templates) {
    if (key.startsWith('searchwidget.')) {
      displayDictionary[key] = config.application_string_templates[key];
    }
  }

  return {
    css: config.search_widget.css || '',
    customer_code: customerCode,
    display_dictionary: displayDictionary,
    params: { ...config.search_widget },
    template: {},
    theme: { ...config.theme }
  };
}

/**
 * Util function to do a simple validation of the response from the search-widget-api
 *
 * @param {object} searchWidgetConfig - the config returned from the search-widget-api
 * @returns boolean - true if config is invalid, else false
 */
export function configIsInvalid(searchWidgetConfig) {
  // treat empty object "{}" as undefined
  if (!searchWidgetConfig || Object.keys(searchWidgetConfig).length === 0) {
    return true;
  } else {
    return false;
  }
}

export default SearchWidget;
